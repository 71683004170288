import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HeaderBottom from "../../HomePage/HeaderBottom";

const StripeEdit = () => {
    const [stripeKey, setStripeKey] = useState(null);

    useEffect(() => {
        fetchStripeKeys();
    }, []);

    const fetchStripeKeys = () => {
        fetch(`https://environmentalseo10cloneclone-34dab1587073.herokuapp.com/stripe-keys`)
            .then((res) => res.json())
            .then((info) => {
                if (info.length > 0) {
                    setStripeKey(info[0]);
                } else {
                    setStripeKey(null);
                }
            });
    };

    const handleEditPayment = (event, id) => {
        event.preventDefault();
        const stripeKey = event.target.stripeKey.value;
        const edit = { stripeKey };
        const url = `https://environmentalseo10cloneclone-34dab1587073.herokuapp.com/stripe-key/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(edit),
        })
            .then((res) => res.json())
            .then((result) => {
                toast.success('Successfully Updated!');
                event.target.reset();
                closeModal(id);
                fetchStripeKeys();
            })
            .catch((error) => {
                console.error('Error updating stripe key:', error);
            });
    };

    const handleAddStripeKey = (event) => {
        event.preventDefault();
        const stripeKey = event.target.stripeKey.value;
        const newStripeKey = { stripeKey };
        fetch('https://environmentalseo10cloneclone-34dab1587073.herokuapp.com/add-stripe-key', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(newStripeKey),
        })
            .then((res) => res.json())
            .then((result) => {
                toast.success('Successfully Added!');
                event.target.reset();
                fetchStripeKeys();
            })
            .catch((error) => {
                console.error('Error adding stripe key:', error);
            });
    };

    const closeModal = (id) => {
        const modal = document.querySelector(`.colorModal${id}`);
        if (modal) {
            modal.classList.remove('show');
        }

        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };

    return (
        <div className="min-vh-100">
        <HeaderBottom></HeaderBottom>
        <div className="main-content">
             <div className="page-content mt-4">
                 <div className="container-fluid">
                     <div className="row">
                         <div className="col-12">
                             <div className="card">
                                 <div className="card-body">
                                     <h4 className="card-title">
                                         {stripeKey ? "Edit" : "Add"} Stripe Publishable Key
                                     </h4>
 
                                     {stripeKey ? (
                                         <div className="table-responsive">
                                             <table className="table table-editable table-nowrap align-middle table-edits">
                                                 <thead>
                                                     <tr>
                                                         <th>Publishable key</th>
                                                         <th>Edit</th>
                                                     </tr>
                                                 </thead>
                                                 <tbody>
                                                     <tr key={stripeKey._id}>
                                                         <td data-field="age">{stripeKey.stripeKey}</td>
                                                         <td style={{ width: '10%' }}>
                                                             <button className="btn btn-outline-secondary btn-sm edit" title="Edit" type="button" data-bs-toggle="modal" data-bs-target={`#colorModal${stripeKey._id}`}>
                                                                 <i className="fas fa-pencil-alt"></i>
                                                             </button>
                                                             <div className={`modal fade colorModal${stripeKey._id}`} id={`colorModal${stripeKey._id}`} tabIndex="-1" aria-labelledby={`exampleModalLabelStatus${stripeKey._id}`} aria-hidden="true">
                                                                 <div className="modal-dialog modal-dialog-centered">
                                                                     <div className="modal-content">
                                                                         <div className="modal-header">
                                                                             <h5 className="modal-title" id={`exampleModalLabelStatus${stripeKey._id}`}>Edit</h5>
                                                                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                         </div>
                                                                         <div className="modal-body">
                                                                             <form className="comment-form" onSubmit={(event) => handleEditPayment(event, stripeKey._id)}>
                                                                                 <div className="fadeInUp style-2 text-center">
                                                                                     <div className="main-title"><h3>Edit Publishable key</h3></div>
                                                                                 </div>
                                                                                 <div className="columns gap20">
                                                                                     <fieldset className="email">
                                                                                         <label className="mb-2">Publishable key</label>
                                                                                         <input
                                                                                             type="text"
                                                                                             placeholder="Enter Publishable key"
                                                                                             className="form-control"
                                                                                             name="stripeKey"
                                                                                             defaultValue={stripeKey.stripeKey}
                                                                                         />
                                                                                     </fieldset>
                                                                                 </div>
                                                                                 <div className="text-center">
                                                                                     <button className="btn btn-success waves-effect waves-light mt-5" type="submit">
                                                                                         Update
                                                                                     </button>
                                                                                 </div>
                                                                             </form>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         </td>
                                                     </tr>
                                                 </tbody>
                                             </table>
                                         </div>
                                     ) : (
                                         <form className="comment-form" onSubmit={handleAddStripeKey}>
                                             <div className="fadeInUp style-2 text-center">
                                                 <div className="main-title"><h3>Add Publishable key</h3></div>
                                             </div>
                                             <div className="columns gap20">
                                                 <fieldset className="email">
                                                     <label className="mb-2">Publishable key</label>
                                                     <input
                                                         type="text"
                                                         placeholder="Enter Publishable key"
                                                         className="form-control"
                                                         name="stripeKey"
                                                         required
                                                     />
                                                 </fieldset>
                                             </div>
                                             <div className="text-center">
                                                 <button className="btn btn-success waves-effect waves-light mt-5" type="submit">
                                                     Add
                                                 </button>
                                             </div>
                                         </form>
                                     )}
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
        </div>
      
    );
};

export default StripeEdit;
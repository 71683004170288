import React, { useState } from "react";
import "./PlaceOrder.css";

const Api = () => {
  const [prompt, setPrompt] = useState("");
  const [article, setArticle] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Make API call to generate the article
    try {
      const response = await fetch("https://api.openai.com/v1/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 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",
        },
        body: JSON.stringify({
          prompt: prompt,
          // You can specify additional parameters here, such as 'model'
          // For example: "model": "text-davinci-003"
        }),
      });

      console.log("Response status:", response.status);
      const data = await response.json();
      console.log("Response data:", data);

      if (!response.ok) {
        throw new Error("Failed to generate article");
      }

      // Assuming your API returns the generated article in JSON format with key 'choices'
      // You may need to adjust this based on the actual response structure
      if (data.choices && data.choices.length > 0) {
        setArticle(data.choices[0].text);
        setError(null);
      } else {
        setError("Failed to generate article");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to generate article");
    }
  };

  return (
    <div className="container mx-auto max-w-md items-center mt-5">
      <h2>Generate Article Now</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="prompt">Enter Prompt:</label>
          <input
            type="text"
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <input type="submit" value="Generate Article" />
      </form>
      {error && <p>{error}</p>}
      <p>{article}</p>
    </div>
  );
};

export default Api;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAcJiiugU4pjqUZLr5nmVRcH3fHhDYM5K4",
  authDomain: "environmentalchemicalseo18.firebaseapp.com",
  projectId: "environmentalchemicalseo18",
  storageBucket: "environmentalchemicalseo18.appspot.com",
  messagingSenderId: "259773618388",
  appId: "1:259773618388:web:c21cd2203b8b1c6a0e8341"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;